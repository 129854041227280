import axios from "axios"
import { Helper } from "./helper"
import { ENV_API_BASE_URL } from "./secrets"

const client = axios.create({
  baseURL: ENV_API_BASE_URL ? `${ENV_API_BASE_URL}` : `${Helper.getApiUrl()}`,
  withCredentials: true,
})

export const APIHelper = {
  requestDemo: data => client.post(`/sales/request-demo`, data),
  contactUs: data => client.post(`/sales/contact-us`, data),
  sendConsultation: data => client.post(`/sales/consult`, data),
  sendOpportunities: data => client.post(`/sales/opportunities`, data),
  demoAccess: data => client.post(`/console/request-access`, data),
  getVoltshareVersion: () => client.get(`/voltshare-version`),
}
