import React from "react"
import { SelectedMenuContextProvider } from "./selected-menu-context"
import { DoNotTrackContextProvider } from "./do-not-track.context"
import { ShowAnnouncementContextProvider } from "./show-announcement.context"
import { LanguageContextProvider } from "./lang.context"
import { VoltshareVersionContextProvider } from "./voltshare-version.context"

const withAppContext = Component => {
  return props => (
    <SelectedMenuContextProvider>
      <DoNotTrackContextProvider>
        <LanguageContextProvider>
          <ShowAnnouncementContextProvider>
            <VoltshareVersionContextProvider>
              <Component {...props} />
            </VoltshareVersionContextProvider>
          </ShowAnnouncementContextProvider>
        </LanguageContextProvider>
      </DoNotTrackContextProvider>
    </SelectedMenuContextProvider>
  )
}

export default withAppContext
