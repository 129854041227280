import React, { useCallback, useState } from "react"
import { APIHelper } from "../utils/api-helper"

export const VoltshareVersionContext = React.createContext({
  version: "",
  getVoltshareVersion: () => {},
})

export const VoltshareVersionContextProvider = props => {
  const initialState = {
    version: "",
  }

  const [state, setState] = useState(initialState)

  const getVoltshareVersion = useCallback(() => {
    if (state.version) {
      return Promise.resolve(state.version)
    } else {
      return APIHelper.getVoltshareVersion()
        .then(res => {
          setState({ ...state, version: res.data })
          return Promise.resolve(res.data)
        })
        .catch(() => {
          console.error(`There was an error getting VoltShare version`)
          return Promise.reject(process.env.GATSBY_VOLTSHARE_DEFAULT_VERSION)
        })
    }
  }, [state])

  return (
    <VoltshareVersionContext.Provider
      value={{ value: state, getVoltshareVersion }}>
      {props.children}
    </VoltshareVersionContext.Provider>
  )
}
