import styles from "./layout.module.scss"
import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Footer from "../footer/footer"
import Header from "../header/header"
import { SelectedMenuContext } from "../../contexts/selected-menu-context"
import { MENU_MOBILE } from "../../utils/constants"
import { Helmet } from "react-helmet"
import { DoNotTrackContext } from "../../contexts/do-not-track.context"
import cssVars from "css-vars-ponyfill"
import { ENV_ENVIRONMENT } from "../../utils/secrets"
import { ShowAnnouncementContext } from "../../contexts/show-announcement.context"
import CookieConsent from "../cookie-consent/cookie-consent"

const Layout = props => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          siteName
        }
      }
    }
  `)

  const { selectedMenu } = useContext(SelectedMenuContext)
  const { showAnnouncement } = useContext(ShowAnnouncementContext)

  const { doNotTrack, setDoNotTrack } = useContext(DoNotTrackContext)

  useEffect(() => {
    // Setting Do Not Track Context
    const dnt =
      navigator.doNotTrack || window.doNotTrack || navigator.msDoNotTrack
    setDoNotTrack(dnt === "1" || dnt === "yes")

    const sAgent = window.navigator.userAgent
    const Idx = sAgent.indexOf("MSIE")

    // If IE, launch ponyfill for CSS variables
    if (Idx > 0 || Boolean(navigator.userAgent.match(/Trident\/7\./))) {
      cssVars({
        preserveVars: true,
        silent: ENV_ENVIRONMENT === "production",
        watch: true,
        variables: {
          "--vh": `${window.innerHeight / 100}px`,
        },
      })
    }
  }, [setDoNotTrack])

  const localize = {
    fn: `!function(a){if(!a.Localize){a.Localize={};for(var e=["translate","untranslate","phrase","initialize","translatePage","setLanguage","getLanguage","getSourceLanguage","detectLanguage","getAvailableLanguages","untranslatePage","bootstrap","prefetch","on","off","hideWidget","showWidget"],t=0;t<e.length;t++)a.Localize[e[t]]=function(){}}}(window);`,
    initScript: `
    Localize.initialize({
      key: 'I3FFcXr7F8Rof',
      rememberLanguage: true
    });
  `,
  }

  return (
    <div className={styles.container}>
      {selectedMenu === MENU_MOBILE && (
        <Helmet
          bodyAttributes={{
            class: "overflow-hidden",
          }}
        />
      )}

      <Helmet>
        <script src="https://global.localizecdn.com/localize.js" />
        <script>{localize.fn}</script>
        <script>{localize.initScript}</script>
        <link rel="preconnect" href="https://fonts.gstatic.com"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap"
          rel="stylesheet"></link>
      </Helmet>

      <CookieConsent />

      <Header siteTitle={data.site.siteMetadata.siteName} />
      <main
        className={
          (showAnnouncement ? styles.mainWithAnnouncement : styles.main) +
          (selectedMenu === MENU_MOBILE ? ` ${styles.grayFilter}` : "")
        }>
        {props.children}
      </main>
      {props.hasFooter && <Footer />}
    </div>
  )
}

Layout.defaultProps = {
  hasFooter: true,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
