import styles from "./footer.module.scss"
import React from "react"
import { Link } from "gatsby"
import {
  ENV_API_DOCS_BASE_URL,
  ENV_DEV_PORTAL_BASE_URL,
} from "../../utils/secrets"
import {
  CAREERS_PAGE_URL,
  PRIVACY_POLICY_PAGE_URL,
} from "../../utils/constants"
import LinkedinSvg from "../svg/linkedin"
import TwitterSvg from "../svg/twitter"
import LangSelector from "../lang-selector/lang-selector"

const Footer = () => {
  return (
    <>
      <footer>
        <div className={styles.footer}>
          <div className={`container ${styles.footerContainer}`}>
            <div className={`row ${styles.menu}`}>
              <div className={`${styles.menu__item} col-6 col-lg-3`}>
                <p className={`${styles.menu__itemTitle}`}>Products</p>
                <Link
                  aria-label="VoltMesh"
                  to={"/products/voltmesh"}
                  className={styles.menu__largeItem}>
                  <div
                    className={`${styles.menu__largeItemIcon} ${styles.menu__largeItemIconVm}`}
                  />
                  <p className={styles.menu__largeItemTitle}>
                    VoltMesh
                    <span className={styles.menu__largeItemTitleSuperscript}>
                      &trade;
                    </span>
                  </p>
                </Link>
                <Link
                  aria-label="VoltStack"
                  to={"/products/voltstack"}
                  className={`${styles.menu__largeItem} ${styles.menu__largeItemNoIndent}`}>
                  <div
                    className={`${styles.menu__largeItemIcon} ${styles.menu__largeItemIconVs}`}
                  />
                  <p className={styles.menu__largeItemTitle}>
                    VoltStack
                    <span className={styles.menu__largeItemTitleSuperscript}>
                      &trade;
                    </span>
                  </p>
                </Link>
                <p
                  className={`${styles.menu__itemTitle} ${styles.menu__itemTitleIndent}`}>
                  Technologies
                </p>
                <Link
                  aria-label="Volterra Global Network"
                  to="/products/volterra-global-network"
                  className={styles.menu__largeItem}>
                  <div
                    className={`${styles.menu__largeItemIcon} ${styles.menu__largeItemIconVgn}`}
                  />
                  <p className={styles.menu__largeItemTitle}>
                    Volterra Global Network
                  </p>
                </Link>
                <Link
                  aria-label="VoltConsole"
                  to="/products/voltconsole"
                  className={styles.menu__largeItem}>
                  <div
                    className={`${styles.menu__largeItemIcon} ${styles.menu__largeItemIconVc}`}
                  />
                  <p className={styles.menu__largeItemTitle}>
                    VoltConsole
                    <span className={styles.menu__largeItemTitleSuperscript}>
                      &trade;
                    </span>
                  </p>
                </Link>
                <Link
                  aria-label="VoltShare"
                  to="/products/voltshare"
                  className={`${styles.menu__largeItem} ${styles.menu__largeItemNoIndent}`}>
                  <div
                    className={`${styles.menu__largeItemIcon} ${styles.menu__largeItemIconVsh}`}
                  />
                  <p className={styles.menu__largeItemTitle}>
                    VoltShare
                    <span className={styles.menu__largeItemTitleSuperscript}>
                      &trade;
                    </span>
                  </p>
                </Link>
              </div>
              <div className={`${styles.menu__item} col-6 col-lg-4`}>
                <p className={`${styles.menu__itemTitle}`}>Solutions</p>
                <p
                  className={`${styles.menu__itemTitle} ${styles.menu__itemTitleSecondary}`}>
                  Multi-Cloud
                </p>
                <Link
                  to={"/solutions/multi-cloud/multi-cloud-networking"}
                  className={styles.menu__itemLink}>
                  Multi-Cloud Networking
                </Link>
                <Link
                  to={"/solutions/multi-cloud/secure-kubernetes-gateway"}
                  className={styles.menu__itemLink}>
                  Secure Kubernetes Gateway
                </Link>
                <p
                  className={`${styles.menu__itemTitle} ${styles.menu__itemTitleSecondary}`}>
                  Network Cloud
                </p>
                <Link
                  to={"/solutions/network/ddos-mitigation"}
                  className={styles.menu__itemLink}>
                  DDoS Mitigation
                </Link>
                <Link
                  to={"/solutions/network/web-app-security-and-performance"}
                  className={styles.menu__itemLink}>
                  Web App Security & Performance
                </Link>
                <Link
                  to={"/solutions/network/app-delivery-network"}
                  className={styles.menu__itemLink}>
                  App Delivery Network (ADN)
                </Link>
                <p
                  className={`${styles.menu__itemTitle} ${styles.menu__itemTitleSecondary}`}>
                  Edge Cloud
                </p>
                <Link
                  to={"/solutions/edge/edge-infrastructure-and-app-management"}
                  className={styles.menu__itemLink}>
                  Infrastructure & App Management
                </Link>
                <Link
                  to={"/solutions/edge/edge-networking-and-security"}
                  className={styles.menu__itemLink}>
                  Edge Networking & Security
                </Link>
              </div>
              <div className={`${styles.menu__item} col-6 col-lg-3`}>
                <p className={styles.menu__itemTitle}>Resources</p>
                <Link
                  to={"/resources/center"}
                  className={styles.menu__itemLink}>
                  Resource Center
                </Link>
                <Link
                  aria-label="Videos"
                  to={"/resources/videos"}
                  className={styles.menu__itemLink}>
                  Videos
                </Link>
                <Link
                  aria-label="Resources"
                  to={"/resources/blog"}
                  className={styles.menu__itemLink}>
                  Blog
                </Link>
                <a
                  aria-label="Technical Docs"
                  href="https://training.volterra.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.menu__itemLink}>
                  Volterra Training
                </a>
                <a
                  aria-label="Technical Docs"
                  href="/docs"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.menu__itemLink}>
                  Technical Documentation
                </a>
                <a
                  aria-label="Open API Reference"
                  href={ENV_API_DOCS_BASE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.menu__itemLink}>
                  API Reference
                </a>
                <a
                  aria-label="API Developer Portal"
                  href={ENV_DEV_PORTAL_BASE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.menu__itemLink}>
                  API Developer Portal
                </a>
                <p
                  className={`${styles.menu__itemTitle} ${styles.menu__itemTitleIndent}`}>
                  Pricing
                </p>
                <Link
                  aria-label="Pricing overview"
                  to="/pricing"
                  className={styles.menu__itemLink}>
                  Overview
                </Link>
              </div>
              <div className={`${styles.menu__item} col-6 col-lg-2`}>
                <p className={styles.menu__itemTitle}>Company</p>
                <Link
                  aria-label="About Us"
                  to={"/company/about"}
                  className={styles.menu__itemLink}>
                  About Us
                </Link>
                <a
                  href={CAREERS_PAGE_URL}
                  aria-label="Careers"
                  target="_blank"
                  className={styles.menu__itemLink}>
                  Careers
                </a>
                <Link
                  to={"/company/contact-us"}
                  className={styles.menu__itemLink}>
                  Contact Us
                </Link>

                <p
                  className={`${styles.menu__itemTitle} ${styles.menu__itemTitleIndent}`}>
                  Support
                </p>
                <a
                  aria-label="Quick Start"
                  href="/docs/quick-start"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.menu__itemLink}>
                  Quick Start
                </a>
                <a
                  aria-label="System Status"
                  href="https://status.volterra.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.menu__itemLink}>
                  System Status
                </a>
                <a
                  aria-label="Feature Requests"
                  href="https://ideas.volterra.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.menu__itemLink}>
                  Feature Requests
                </a>
                <Link
                  aria-label="Compliance"
                  to={"/company/compliance"}
                  className={styles.menu__itemLink}>
                  Compliance
                </Link>
              </div>
            </div>
            <div className={`row ${styles.footer__end}`}>
              <div className={`${styles.footer__body} col-12 col-md-10`}>
                <span className={styles.footer__rights}>
                  © {new Date().getFullYear()} Volterra Inc. All rights reserved
                </span>
                <Link
                  aria-label="Terms of Use"
                  to={"/terms"}
                  className={styles.footer__terms}>
                  Terms of Use
                </Link>
                <a
                  href={PRIVACY_POLICY_PAGE_URL}
                  aria-label="Privacy Policy"
                  target="_blank"
                  className={styles.footer__terms}>
                  Privacy
                </a>
                <a
                  href="https://www.f5.com/company/policies/F5-California-privacy-summary"
                  aria-label="California Privacy"
                  target="_blank"
                  className={styles.footer__terms}>
                  California Privacy
                </a>
                <a
                  href="https://www.f5.com/company/policies/privacy-notice#no-sell"
                  aria-label="Do Not Sell My Personal Information"
                  target="_blank"
                  className={styles.footer__terms}>
                  Do Not Sell My Personal Information
                </a>
                <Link
                  aria-label="Cookie Policy"
                  to={"/cookie-policy"}
                  className={styles.footer__terms}>
                  Cookie Policy
                </Link>
              </div>
              <div className={`${styles.social} col-2`}>
                <span className={styles.language}>
                  <LangSelector props={{ dropUp: true }} />
                </span>
                <a
                  aria-label="Open Twitter"
                  href="https://twitter.com/f5"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.social__link}>
                  <TwitterSvg />
                </a>
                <a
                  aria-label="Open Linkedin"
                  href="https://www.linkedin.com/company/f5/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.social__link}>
                  <LinkedinSvg />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
