import styles from "./lang-selector.module.scss"
import React, { useContext, useState } from "react"
import ArrowSvg from "../svg/arrow"
import GlobeSvg from "../svg/globe"
import { LanguageContext } from "../../contexts/lang.context"
import { Helper } from "../../utils/helper"

const LangSelector = ({ props }) => {
  const { setLanguage } = useContext(LanguageContext)
  const [isOpened, setOpened] = useState(false)

  const mouseEnterHandler = value => {
    if (!Helper.isTouchScreen()) {
      // triggering MouseEnter event handler only if device doesn't have touchscreen
      setOpened(value)
    }
  }

  const changeLang = event => {
    setOpened(false)
    let langCode = event.target.getAttribute("data-lang").toLowerCase()
    setLanguage(langCode)
    window.Localize.setLanguage(langCode)
  }

  return (
    <div
      onMouseEnter={() => mouseEnterHandler(true)}
      onMouseLeave={() => setOpened(false)}
      onClick={() => setOpened(!isOpened)}
      className={
        props && props.dropUp
          ? `${styles.lang} ${styles.langDropup}`
          : styles.lang
      }>
      <button className={styles.langLink}>
        <span className={styles.langLinkGlobe}>
          <GlobeSvg />
        </span>
        <span className={styles.langSet} />
        <span
          className={
            props && props.hideCaret
              ? `${styles.langLinkCaret} ${styles.langLinkCaretHide}`
              : styles.langLinkCaret
          }>
          <ArrowSvg />
        </span>
      </button>
      <div
        className={`${styles.dropdownMenu} ${
          isOpened === true ? styles.dropdownMenuOpen : ""
        }`}>
        <button
          data-lang="EN"
          className={styles.dropdownItem}
          onClick={changeLang}>
          English
        </button>
        <button
          data-lang="JA"
          className={styles.dropdownItem}
          onClick={changeLang}>
          Japanese - 日本語
        </button>
      </div>
    </div>
  )
}

export default LangSelector
