import styles from "./header.module.scss"
import AnnouncementJSON from "./../../../data/config/announcement"
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { Link } from "gatsby"
import {
  MENU_COMPANY,
  MENU_MOBILE,
  MENU_PRICING,
  MENU_PRODUCTS,
  MENU_RESOURCES,
  MENU_SOLUTIONS,
  MENU_SUPPORT,
  CAREERS_PAGE_URL,
} from "../../utils/constants"
import {
  ENV_CONSOLE_BASE_URL,
  ENV_DEV_PORTAL_BASE_URL,
} from "../../utils/secrets"
import { SelectedMenuContext } from "../../contexts/selected-menu-context"
import CrossSvg from "../svg/cross"
import { ShowAnnouncementContext } from "../../contexts/show-announcement.context"
import ArrowSvg from "../svg/arrow"
import { LanguageContext } from "../../contexts/lang.context"

const Header = () => {
  const { selectedMenu, setSelectedMenu } = useContext(SelectedMenuContext)
  const { language } = useContext(LanguageContext)

  const headerRef = useRef()

  useEffect(() => {
    const windowExists = typeof window !== "undefined"

    if (windowExists) {
      // forcing first-time invoke to apply correct popup size for mobile devices (https://gitlab.com/volterra/ves.io/flatrender/-/issues/209)
      handleResize()

      window.addEventListener("resize", handleResize)
      return () => window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleResize = () => {
    headerRef.current.style.setProperty("--vh", `${window.innerHeight / 100}px`)
  }

  const setSelectedMenuWrapper = (event, value) => {
    event.stopPropagation()
    if (selectedMenu === MENU_MOBILE) {
      // skipping this for non-touch screens with width below 1200px
      return
    }
    setSelectedMenu(value)
  }

  const toggleSelectedMenu = (event, value) => {
    event.stopPropagation()
    setSelectedMenu(!selectedMenu ? value : null)
  }

  const loginClickHandler = () => {
    window.location.href = `${ENV_CONSOLE_BASE_URL}/`
  }

  const signUpClickHandler = () => {
    window.location.href = `${ENV_CONSOLE_BASE_URL}/signup/start`
  }

  const { showAnnouncement, setShowAnnouncement } = useContext(
    ShowAnnouncementContext
  )

  const [isClient, setClient] = useState(false)

  // rehydrating component to make sure language-specific styles are applied in SSR mode
  // ref https://github.com/gatsbyjs/gatsby/issues/17914
  useEffect(() => {
    setClient(true)
  })

  return (
    <React.Fragment key={isClient}>
      <div id="header" className={styles.header} ref={headerRef}>
        {showAnnouncement && (
          <div
            className={`${styles.announcement} ${
              language === "ja" ? styles.announcementJa : ""
            }`}>
            <div className={styles.announcement__container}>
              <div className={styles.announcement__body}>
                <h5 className={styles.announcement__title}>
                  {AnnouncementJSON.title}
                </h5>
                <p className={styles.announcement__text}>
                  {AnnouncementJSON.text}
                </p>
                {AnnouncementJSON.link && !AnnouncementJSON.link.external && (
                  <Link
                    aria-label="Open Announcement link"
                    to={AnnouncementJSON.link.url}
                    rel="noopener noreferrer"
                    className={styles.announcement__link}>
                    {AnnouncementJSON.link.text}
                    <ArrowSvg />
                  </Link>
                )}
                {AnnouncementJSON.link && AnnouncementJSON.link.external && (
                  <a
                    aria-label="Open Announcement link"
                    href={AnnouncementJSON.link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.announcement__link}>
                    {AnnouncementJSON.link.text}
                    <ArrowSvg />
                  </a>
                )}
                {AnnouncementJSON.button && (
                  <button
                    onClick={() => {
                      window.open("/download-latest-voltshare", "_blank")
                    }}
                    aria-label={AnnouncementJSON.button.text}
                    rel="noopener noreferrer"
                    className={styles.announcement__button}>
                    {AnnouncementJSON.button.text}
                  </button>
                )}
              </div>
              <div
                aria-label="Close Announcement"
                role="button"
                onClick={() => {
                  setShowAnnouncement(false)
                }}
                className={styles.announcement__cross}>
                <CrossSvg />
              </div>
            </div>
          </div>
        )}

        <div
          aria-label="Close Menu"
          role="button"
          className={`header-wrapper container ${styles.header__container}`}
          onMouseLeave={e => {
            setSelectedMenuWrapper(e, null)
          }}>
          <div className={`row ${styles.menu}`}>
            <div className={styles.menu__left}>
              <Link aria-label="Homepage" className={styles.logo} to={"/"} />
              <div
                ves-e2e-test="header/menu-group-item"
                aria-label="Open Products Menu"
                role="button"
                onClick={e => {
                  toggleSelectedMenu(e, MENU_PRODUCTS)
                }}
                onMouseEnter={e => {
                  setSelectedMenuWrapper(e, MENU_PRODUCTS)
                }}
                className={`${styles.menu__item} ${
                  selectedMenu === MENU_PRODUCTS ? styles.menu__itemActive : ""
                }`}>
                <div className={styles.menu__itemBody}>Products</div>
                <div
                  ves-e2e-test="header/group-link-item"
                  className={
                    styles.products +
                    " " +
                    (selectedMenu === MENU_PRODUCTS
                      ? styles.animationAppear
                      : "") +
                    " " +
                    (selectedMenu !== MENU_PRODUCTS
                      ? styles.animationDisappear
                      : "") +
                    " "
                  }>
                  <div className={styles.products__typeList}>
                    <div
                      className={`pr-lg-0 ${styles.products__typeItem} ${styles.products__typeItemHighlight}`}>
                      <p className={styles.products__typeItemTitle}>Products</p>
                      <Link
                        ves-e2e-test="header/group-link-title"
                        to={"/products/voltmesh"}
                        className={styles.products__row}>
                        <div
                          className={`${styles.products__icon} ${styles.products__iconVm}`}
                        />
                        <div className={styles.products__body}>
                          <p className={styles.products__title}>
                            VoltMesh
                            <span className={styles.products__titleSuperscript}>
                              &trade;
                            </span>
                          </p>
                          <p
                            className={`${styles.products__description} ${styles.products__descriptionSpaced}`}>
                            Distributed networking & security service
                          </p>
                        </div>
                      </Link>
                      <Link
                        ves-e2e-test="header/group-link-title"
                        to={"/products/voltstack"}
                        className={styles.products__row}>
                        <div
                          className={`${styles.products__icon} ${styles.products__iconVs}`}
                        />
                        <div className={styles.products__body}>
                          <p className={styles.products__title}>
                            VoltStack
                            <span className={styles.products__titleSuperscript}>
                              &trade;
                            </span>
                          </p>
                          <p className={`mb-0 ${styles.products__description}`}>
                            Platform services for distributed apps &
                            infrastructure
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className={styles.products__typeItem}>
                      <p className={styles.products__typeItemTitle}>
                        New Capabilities
                      </p>
                      <Link
                        ves-e2e-test="header/group-link-title"
                        to={
                          "https://www.f5.com/products/security/application-traffic-insight"
                        }
                        className={`${styles.products__row} ${styles.products__rowSpec}`}>
                        <div className={styles.products__body}>
                          <p className={styles.products__description}>
                            Application Traffic Insight
                          </p>
                        </div>
                      </Link>
                      <Link
                        ves-e2e-test="header/group-link-title"
                        to={
                          "/products/voltmesh-multi-cluster-load-balancer-and-api-gateway"
                        }
                        className={`${styles.products__row} ${styles.products__rowSpec}`}>
                        <div className={styles.products__body}>
                          <p className={styles.products__description}>
                            Multi-Cluster Load Balancer & API Gateway
                          </p>
                        </div>
                      </Link>
                      <Link
                        ves-e2e-test="header/group-link-title"
                        to={
                          "/products/voltmesh-automated-zero-trust-and-api-security"
                        }
                        className={`${styles.products__row} ${styles.products__rowSpec} ${styles.products__rowSpecSec}`}>
                        <div className={styles.products__body}>
                          <p className={styles.products__description}>
                            Automated Zero-Trust & API Security
                          </p>
                        </div>
                      </Link>
                    </div>
                    <div className={styles.products__typeItem}>
                      <p className={styles.products__typeItemTitle}>
                        Technologies
                      </p>
                      <Link
                        ves-e2e-test="header/group-link-title"
                        to={"/products/volterra-global-network"}
                        className={styles.products__row}>
                        <div
                          className={`${styles.products__icon} ${styles.products__iconVgn}`}
                        />
                        <div className={styles.products__body}>
                          <p className={styles.products__title}>
                            Volterra Global Network
                          </p>
                          <p className={styles.products__description}>
                            Optimized for app-to-app connectivity & security
                          </p>
                        </div>
                      </Link>
                      <Link
                        ves-e2e-test="header/group-link-title"
                        to={"/products/voltconsole"}
                        className={styles.products__row}>
                        <div
                          className={`${styles.products__icon} ${styles.products__iconVc}`}
                        />
                        <div className={styles.products__body}>
                          <p className={styles.products__title}>
                            VoltConsole
                            <span className={styles.products__titleSuperscript}>
                              &trade;
                            </span>
                          </p>
                          <p className={styles.products__description}>
                            Single pane of glass for distributed apps &
                            infrastructure
                          </p>
                        </div>
                      </Link>
                      <Link
                        ves-e2e-test="header/group-link-title"
                        to={"/products/voltshare"}
                        className={styles.products__row}>
                        <div
                          className={`${styles.products__icon} ${styles.products__iconVsh}`}
                        />
                        <div className={styles.products__body}>
                          <p className={styles.products__title}>
                            VoltShare
                            <span className={styles.products__titleSuperscript}>
                              &trade;
                            </span>
                          </p>
                          <p className={`mb-0 ${styles.products__description}`}>
                            Securely share or store information using existing
                            workflows
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                ves-e2e-test="header/menu-group-item"
                aria-label="Open Solutions Menu"
                role="button"
                onClick={e => {
                  toggleSelectedMenu(e, MENU_SOLUTIONS)
                }}
                onMouseEnter={e => {
                  setSelectedMenuWrapper(e, MENU_SOLUTIONS)
                }}
                className={`${styles.menu__item} ${
                  selectedMenu === MENU_SOLUTIONS ? styles.menu__itemActive : ""
                }`}>
                <div className={styles.menu__itemBody}>Solutions</div>
                <div
                  className={
                    styles.solutions +
                    " " +
                    (selectedMenu === MENU_SOLUTIONS
                      ? styles.animationAppear
                      : "") +
                    " " +
                    (selectedMenu !== MENU_SOLUTIONS
                      ? styles.animationDisappear
                      : "") +
                    " "
                  }>
                  <div className="container">
                    <div className="row">
                      <div
                        className={`col-4 ${styles.solutions__item}`}
                        ves-e2e-test="header/group-link-item">
                        <div className={styles.solutions__itemRow}>
                          <p className={styles.solutions__itemTitle}>
                            Multi-Cloud
                          </p>
                        </div>
                        <Link
                          ves-e2e-test="header/group-link-title"
                          to={"/solutions/multi-cloud/multi-cloud-networking"}
                          className={styles.solutions__itemLink}>
                          Multi-Cloud Networking
                          <div className={styles.solutions__itemDescription}>
                            Global multi-cloud network, security, load
                            balancer/ADC
                          </div>
                        </Link>
                        <Link
                          ves-e2e-test="header/group-link-title"
                          to={
                            "/solutions/multi-cloud/secure-kubernetes-gateway"
                          }
                          className={`mb-0 ${styles.solutions__itemLink}`}>
                          Secure Kubernetes Gateway
                          <div className={styles.solutions__itemDescription}>
                            K8s ingress/egress controller, WAF, API gateway,
                            multi-cluster service mesh
                          </div>
                        </Link>
                      </div>
                      <div
                        className={`col-4 ${styles.solutions__item}`}
                        ves-e2e-test="header/group-link-item">
                        <div className={styles.solutions__itemRow}>
                          <p className={styles.solutions__itemTitle}>
                            Network Cloud
                          </p>
                        </div>
                        <Link
                          ves-e2e-test="header/group-link-title"
                          to={"/solutions/network/ddos-mitigation"}
                          className={styles.solutions__itemLink}>
                          DDoS Mitigation
                          <div className={styles.solutions__itemDescription}>
                            DDoS mitigation with BGP/DNS or Volterra transit
                          </div>
                        </Link>
                        <Link
                          ves-e2e-test="header/group-link-title"
                          to={
                            "/solutions/network/web-app-security-and-performance"
                          }
                          className={styles.solutions__itemLink}>
                          Web App Security & Performance
                          <div className={styles.solutions__itemDescription}>
                            API security and WAF; acceleration with distributed
                            load balancer/ADC
                          </div>
                        </Link>
                        <Link
                          ves-e2e-test="header/group-link-title"
                          to={"/solutions/network/app-delivery-network"}
                          className={`mb-0 ${styles.solutions__itemLink}`}>
                          App Delivery Network (ADN)
                          <div className={styles.solutions__itemDescription}>
                            App acceleration via K8s workloads running in the
                            Volterra global network
                          </div>
                        </Link>
                      </div>
                      <div
                        className={`col-4 ${styles.solutions__item}`}
                        ves-e2e-test="header/group-link-item">
                        <div className={styles.solutions__itemRow}>
                          <p className={styles.solutions__itemTitle}>
                            Edge Cloud
                          </p>
                        </div>
                        <Link
                          ves-e2e-test="header/group-link-title"
                          to={
                            "/solutions/edge/edge-infrastructure-and-app-management"
                          }
                          className={styles.solutions__itemLink}>
                          Infrastructure & App Management
                          <div className={styles.solutions__itemDescription}>
                            Platform services for a large number of edge
                            locations
                          </div>
                        </Link>
                        <Link
                          ves-e2e-test="header/group-link-title"
                          to={"/solutions/edge/edge-networking-and-security"}
                          className={`mb-0 ${styles.solutions__itemLink}`}>
                          Edge Networking & Security
                          <div className={styles.solutions__itemDescription}>
                            Consolidated networking and security service;
                            connectivity to cloud via Volterra network
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                ves-e2e-test="header/menu-group-item"
                aria-label="Open Resources Menu"
                role="button"
                onClick={e => {
                  toggleSelectedMenu(e, MENU_RESOURCES)
                }}
                onMouseEnter={e => {
                  setSelectedMenuWrapper(e, MENU_RESOURCES)
                }}
                className={`${styles.menu__item} ${
                  selectedMenu === MENU_RESOURCES ? styles.menu__itemActive : ""
                }`}>
                <div className={styles.menu__itemBody}>Resources</div>
                <div
                  className={
                    styles.resources +
                    " " +
                    (selectedMenu === MENU_RESOURCES
                      ? styles.animationAppear
                      : "") +
                    " " +
                    (selectedMenu !== MENU_RESOURCES
                      ? styles.animationDisappear
                      : "") +
                    " "
                  }>
                  <div
                    className={styles.resources__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.resources__icon} ${styles.resources__iconCenter}`}
                    />
                    <Link
                      ves-e2e-test="header/group-link-title"
                      to={"/resources/center"}
                      className={styles.resources__title}>
                      Resource Center
                    </Link>
                  </div>
                  <div className={styles.resources__item}>
                    <div
                      className={`${styles.resources__icon} ${styles.resources__iconVideos}`}
                    />
                    <Link
                      to={"/resources/videos"}
                      className={styles.resources__title}>
                      Videos
                    </Link>
                  </div>
                  <div
                    className={styles.resources__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.resources__icon} ${styles.resources__iconBlog}`}
                    />
                    <Link
                      ves-e2e-test="header/group-link-title"
                      to={"/resources/blog"}
                      className={styles.resources__title}>
                      Blog
                    </Link>
                  </div>
                  <div
                    className={styles.resources__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.resources__icon} ${styles.resources__iconTraining}`}
                    />
                    <a
                      ves-e2e-test="header/group-link-title"
                      href="https://training.volterra.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.resources__title}>
                      Volterra Training
                    </a>
                  </div>
                  <div
                    className={styles.resources__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.resources__icon} ${styles.resources__iconDocs}`}
                    />
                    <a
                      ves-e2e-test="header/group-link-title"
                      aria-label="Technical Docs"
                      href="/docs"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.resources__title}>
                      Technical Documentation
                    </a>
                  </div>
                  <div
                    className={styles.resources__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.resources__icon} ${styles.resources__iconApi}`}
                    />
                    <a
                      ves-e2e-test="header/group-link-title"
                      aria-label="Open API Reference"
                      href="/docs/api"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.resources__title}>
                      API Reference
                    </a>
                  </div>
                  <div
                    className={styles.resources__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.resources__icon} ${styles.resources__iconDevportal}`}
                    />
                    <a
                      ves-e2e-test="header/group-link-title"
                      aria-label="API Developer Portal"
                      href={ENV_DEV_PORTAL_BASE_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.resources__title}>
                      API Developer Portal
                    </a>
                  </div>
                </div>
              </div>
              <div
                ves-e2e-test="header/menu-group-item"
                aria-label="Open Pricing Menu"
                role="button"
                onMouseEnter={e => {
                  setSelectedMenuWrapper(e, MENU_PRICING)
                }}
                className={`${styles.menu__item} ${
                  selectedMenu === MENU_PRICING ? styles.menu__itemActive : ""
                }`}>
                <div className={styles.menu__itemBody}>
                  <Link className={styles.menu__itemBodyLink} to={"/pricing"}>
                    Pricing
                  </Link>
                </div>
              </div>
              <div
                ves-e2e-test="header/menu-group-item"
                aria-label="Open Company Menu"
                role="button"
                onClick={e => {
                  toggleSelectedMenu(e, MENU_COMPANY)
                }}
                onMouseEnter={e => {
                  setSelectedMenuWrapper(e, MENU_COMPANY)
                }}
                className={`${styles.menu__item} ${
                  selectedMenu === MENU_COMPANY ? styles.menu__itemActive : ""
                }`}>
                <div className={styles.menu__itemBody}>Company</div>
                <div
                  className={
                    styles.company +
                    " " +
                    (selectedMenu === MENU_COMPANY
                      ? styles.animationAppear
                      : "") +
                    " " +
                    (selectedMenu !== MENU_COMPANY
                      ? styles.animationDisappear
                      : "") +
                    " "
                  }>
                  <div
                    className={styles.company__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.company__icon} ${styles.company__iconAbout}`}
                    />
                    <Link
                      ves-e2e-test="header/group-link-title"
                      aria-label="About Us"
                      to={"/company/about"}
                      className={styles.company__title}>
                      About Us
                    </Link>
                  </div>
                  <div
                    className={styles.company__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.company__icon} ${styles.company__iconCareers}`}
                    />
                    <a
                      href={CAREERS_PAGE_URL}
                      ves-e2e-test="header/group-link-title"
                      target="_blank"
                      className={styles.company__title}>
                      Careers
                    </a>
                  </div>
                  <div
                    className={styles.company__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.company__icon} ${styles.company__iconContact}`}
                    />
                    <Link
                      ves-e2e-test="header/group-link-title"
                      to={"/company/contact-us"}
                      className={styles.company__title}>
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.menu__right}>
              <div
                ves-e2e-test="header/menu-group-item"
                aria-label="Open Support Menu"
                role="button"
                onClick={e => {
                  toggleSelectedMenu(e, MENU_SUPPORT)
                }}
                onMouseEnter={e => {
                  setSelectedMenuWrapper(e, MENU_SUPPORT)
                }}
                className={`${styles.menu__item} ${
                  selectedMenu === MENU_SUPPORT ? styles.menu__itemActive : ""
                }`}>
                <div className={styles.menu__itemBody}>Support</div>
                <div
                  className={
                    styles.support +
                    " " +
                    (selectedMenu === MENU_SUPPORT
                      ? styles.animationAppear
                      : "") +
                    " " +
                    (selectedMenu !== MENU_SUPPORT
                      ? styles.animationDisappear
                      : "") +
                    " "
                  }>
                  <div
                    className={styles.support__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.support__icon} ${styles.support__iconDocs}`}
                    />
                    <a
                      ves-e2e-test="header/group-link-title"
                      aria-label="Documentation"
                      href="/docs"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.support__title}>
                      Documentation
                    </a>
                  </div>
                  <div
                    className={styles.support__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.support__icon} ${styles.support__iconStart}`}
                    />
                    <a
                      ves-e2e-test="header/group-link-title"
                      aria-label="Quick Start"
                      href="/docs/quick-start"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.support__title}>
                      Quick Start
                    </a>
                  </div>
                  <div
                    className={styles.support__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.support__icon} ${styles.support__iconStatus}`}
                    />
                    <a
                      ves-e2e-test="header/group-link-title"
                      aria-label="System Status"
                      href="https://status.volterra.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.support__title}>
                      System Status
                    </a>
                  </div>
                  <div
                    className={styles.support__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.support__icon} ${styles.support__iconFeatureReqs}`}
                    />
                    <a
                      ves-e2e-test="header/group-link-title"
                      aria-label="Feature Requests"
                      href="https://ideas.volterra.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.support__title}>
                      Feature Requests
                    </a>
                  </div>
                  <div
                    className={styles.support__item}
                    ves-e2e-test="header/group-link-item">
                    <div
                      className={`${styles.support__icon} ${styles.support__iconCompliance}`}
                    />
                    <a
                      ves-e2e-test="header/group-link-title"
                      aria-label="Compliance"
                      href="/company/compliance"
                      className={styles.support__title}>
                      Compliance
                    </a>
                  </div>
                </div>
              </div>
              <button
                aria-label="Log in"
                className={`${styles.menu__button}  ${styles.menu__buttonOutline}`}
                onClick={loginClickHandler}>
                Log in
              </button>
              <button
                aria-label="Sign up"
                onClick={signUpClickHandler}
                className={`${styles.menu__button}`}>
                Sign up
              </button>
            </div>
          </div>
          <div className={`row ${styles.mobileMenu}`}>
            <div className={styles.mobileMenu__icon}>
              {selectedMenu === MENU_MOBILE && (
                <div
                  aria-label="Close Mobile Menu"
                  role="button"
                  className={styles.mobileMenu__iconCross}
                  onClick={e => {
                    e.stopPropagation()
                    setSelectedMenu(null)
                  }}>
                  <CrossSvg />
                </div>
              )}
              {selectedMenu !== MENU_MOBILE && (
                <img
                  aria-label="Open Mobile Menu"
                  role="button"
                  alt="menu icon"
                  onClick={e => {
                    setSelectedMenuWrapper(e, MENU_MOBILE)
                  }}
                  src="/img/menu/menu_icon.svg"
                />
              )}
            </div>
            <Link
              aria-label="Homepage"
              className={styles.mobileMenu__logo}
              to={"/"}
            />
            <div
              className={
                language === "ja"
                  ? `${styles.mobileMenu__right} ${styles.mobileMenu__rightJapanese}`
                  : styles.mobileMenu__right
              }>
              <button
                aria-label="Log in"
                className={`${styles.mobileMenu__button} ${styles.mobileMenu__buttonOutline} ${styles.mobileMenu__buttonLogin}`}
                onClick={loginClickHandler}>
                Log In
              </button>
              <button
                aria-label="Sign up"
                className={
                  language === "ja"
                    ? `${styles.mobileMenu__button} ${styles.mobileMenu__buttonJapanese}`
                    : styles.mobileMenu__button
                }
                onClick={signUpClickHandler}>
                Sign up
              </button>
            </div>
            <div
              onClick={event => {
                toggleSelectedMenu(event, MENU_MOBILE)
              }}
              className={
                styles.mobileMenu__popup +
                " " +
                styles.popup +
                " " +
                (showAnnouncement ? `${styles.mobileMenu__popupIndent} ` : "") +
                (selectedMenu === MENU_MOBILE
                  ? `${styles.animationAppear} `
                  : "") +
                (selectedMenu !== MENU_MOBILE
                  ? `${styles.animationDisappear}`
                  : "")
              }>
              <div className={styles.popup__section}>
                <p className={styles.popup__header}>Products</p>
                <Link
                  to={"/products/voltmesh"}
                  className={`${styles.popup__item} ${styles.popup__itemProducts} ${styles.popup__itemProductsPrimary}`}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconVm}`}
                  />
                  <div className={styles.popup__wrapper}>
                    <p className={styles.popup__title}>
                      VoltMesh<span>&trade;</span>
                    </p>
                    <p
                      className={`${styles.popup__description} ${styles.popup__descriptionPrimary}`}>
                      Distributed networking <br /> & zero-trust security
                    </p>
                  </div>
                </Link>
                <Link
                  to={"/products/voltstack"}
                  className={`${styles.popup__item} ${styles.popup__itemProducts} ${styles.popup__itemProductsPrimary}`}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconVs}`}
                  />
                  <div className={styles.popup__wrapper}>
                    <p className={styles.popup__title}>
                      VoltStack<span>&trade;</span>
                    </p>
                    <p
                      className={`${styles.popup__description} ${styles.popup__descriptionPrimary}`}>
                      Platform for distributed apps <br /> & infrastructure
                    </p>
                  </div>
                </Link>

                <p className={styles.popup__header}>
                  New VoltMesh Capabilities
                </p>
                <Link
                  ves-e2e-test="header/group-link-title"
                  to={
                    "https://www.f5.com/products/security/application-traffic-insight"
                  }
                  className={`${styles.popup__item} ${styles.popup__itemProducts} ${styles.popup__itemProductsShaded}`}>
                  <div
                    className={`w-100 mb-2 ${styles.popup__wrapper} ${styles.popup__wrapperShaded}`}>
                    <p className={`mb-0 ${styles.popup__description}`}>
                      Application Traffic Insight
                    </p>
                  </div>
                </Link>
                <Link
                  ves-e2e-test="header/group-link-title"
                  to={
                    "/products/voltmesh-multi-cluster-load-balancer-and-api-gateway"
                  }
                  className={`${styles.popup__item} ${styles.popup__itemProducts} ${styles.popup__itemProductsShaded}`}>
                  <div
                    className={`w-100 mb-2 ${styles.popup__wrapper} ${styles.popup__wrapperShaded}`}>
                    <p className={`mb-0 ${styles.popup__description}`}>
                      Multi-Cluster Load Balancer & API Gateway
                    </p>
                  </div>
                </Link>
                <Link
                  ves-e2e-test="header/group-link-title"
                  to={
                    "/products/voltmesh-automated-zero-trust-and-api-security"
                  }
                  className={`${styles.popup__item} ${styles.popup__itemProducts} ${styles.popup__itemProductsShaded}`}>
                  <div
                    className={`w-100 mb-4 ${styles.popup__wrapper} ${styles.popup__wrapperShaded}`}>
                    <p className={`mb-0 ${styles.popup__description}`}>
                      Automated Zero-Trust & API Security
                    </p>
                  </div>
                </Link>

                <p className={styles.popup__headerType}>Technologies</p>
                <Link
                  to={"/products/volterra-global-network"}
                  className={`${styles.popup__item} ${styles.popup__itemProducts}`}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconVgn}`}
                  />
                  <div className={styles.popup__wrapper}>
                    <p className={styles.popup__title}>
                      Volterra Global Network
                    </p>
                    <p className={styles.popup__description}>
                      Optimized for app-to-app <br /> connectivity & security
                    </p>
                  </div>
                </Link>
                <Link
                  to={"/products/voltconsole"}
                  className={`${styles.popup__item} ${styles.popup__itemProducts}`}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconVc}`}
                  />
                  <div className={styles.popup__wrapper}>
                    <p className={styles.popup__title}>
                      VoltConsole<span>&trade;</span>
                    </p>
                    <p className={styles.popup__description}>
                      Single pane of glass for distributed
                      <br /> apps & infrastructure
                    </p>
                  </div>
                </Link>
                <Link
                  to={"/products/voltshare"}
                  className={`${styles.popup__item} ${styles.popup__itemProducts}`}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconVsh}`}
                  />
                  <div className={styles.popup__wrapper}>
                    <p className={styles.popup__title}>
                      VoltShare<span>&trade;</span>
                    </p>
                    <p className={styles.popup__description}>
                      Securely share or store information <br /> using existing
                      workflows
                    </p>
                  </div>
                </Link>
              </div>
              <div className={styles.popup__section}>
                <p className={styles.popup__header}>Solutions</p>
                <p
                  aria-label="Multi-Cloud"
                  className={styles.popup__headerType}>
                  Multi-Cloud
                </p>
                <Link
                  to={"/solutions/multi-cloud/multi-cloud-networking"}
                  className={styles.popup__title}>
                  Multi-Cloud Networking
                  <div className={styles.popup__description}>
                    Global multi-cloud network, security, load balancer/ADC
                  </div>
                </Link>
                <Link
                  to={"/solutions/multi-cloud/secure-kubernetes-gateway"}
                  className={`${styles.popup__title} mb-0`}>
                  Secure Kubernetes Gateway
                  <div className={styles.popup__description}>
                    K8s ingress/egress controller, WAF, API gateway,
                    multi-cluster service mesh
                  </div>
                </Link>
                <p
                  aria-label="Network Cloud"
                  className={styles.popup__headerType}>
                  Network Cloud
                </p>
                <Link
                  to={"/solutions/network/ddos-mitigation"}
                  className={styles.popup__title}>
                  DDoS Mitigation
                  <div className={styles.popup__description}>
                    DDoS mitigation with BGP/DNS or Volterra transit
                  </div>
                </Link>
                <Link
                  to={"/solutions/network/web-app-security-and-performance"}
                  className={styles.popup__title}>
                  Web App Security & Performance
                  <div className={styles.popup__description}>
                    API security and WAF; acceleration with distributed load
                    balancer/ADC
                  </div>
                </Link>
                <Link
                  to={"/solutions/network/app-delivery-network"}
                  className={`${styles.popup__title} mb-0`}>
                  App Delivery Network (ADN)
                  <div className={styles.popup__description}>
                    App acceleration via K8s workloads running in the Volterra
                    global network
                  </div>
                </Link>
                <p aria-label="Edge Cloud" className={styles.popup__headerType}>
                  Edge Cloud
                </p>
                <Link
                  to={"/solutions/edge/edge-infrastructure-and-app-management"}
                  className={styles.popup__title}>
                  Infrastructure & App Management
                  <div className={styles.popup__description}>
                    Platform services for a large number of edge locations
                  </div>
                </Link>
                <Link
                  to={"/solutions/edge/edge-networking-and-security"}
                  className={styles.popup__title}>
                  Edge Networking & Security
                  <div className={styles.popup__description}>
                    Consolidated networking and security service; connectivity
                    to cloud via Volterra network
                  </div>
                </Link>
              </div>
              <div className={styles.popup__section}>
                <p className={styles.popup__header}>Resources</p>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconCenter}`}
                  />
                  <Link
                    aria-label="Resources"
                    to={"/resources/center"}
                    className={styles.popup__title}>
                    Resource Center
                  </Link>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconVideos}`}
                  />
                  <Link
                    aria-label="Videos"
                    to={"/resources/videos"}
                    className={styles.popup__title}>
                    Videos
                  </Link>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconBlog}`}
                  />
                  <Link
                    aria-label="Blog"
                    to={"/resources/blog"}
                    className={styles.popup__title}>
                    Blog
                  </Link>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconTraining}`}
                  />
                  <a
                    aria-label="Volterra Training"
                    href="https://training.volterra.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.popup__title}>
                    Volterra Training
                  </a>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconDocs}`}
                  />
                  <a
                    aria-label="Technical Docs"
                    href="/docs"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.popup__title}>
                    Technical Documentation
                  </a>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconApi}`}
                  />
                  <a
                    aria-label="Open API Reference"
                    href="/docs/api"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.popup__title}>
                    API Reference
                  </a>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconDevportal}`}
                  />
                  <a
                    aria-label="API Developer Portal"
                    href={ENV_DEV_PORTAL_BASE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.popup__title}>
                    API Developer Portal
                  </a>
                </div>
              </div>
              <div className={styles.popup__section}>
                <p className={styles.popup__header}>Company</p>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconAbout}`}
                  />
                  <Link
                    aria-label="About Us"
                    to={"/company/about"}
                    className={styles.popup__title}>
                    About Us
                  </Link>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconCareers}`}
                  />
                  <a
                    href={CAREERS_PAGE_URL}
                    aria-label="Careers"
                    target="_blank"
                    className={styles.popup__title}>
                    Careers
                  </a>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconContact}`}
                  />
                  <Link
                    to={"/company/contact-us"}
                    className={styles.popup__title}>
                    Contact Us
                  </Link>
                </div>
              </div>
              <div className={styles.popup__section}>
                <p className={styles.popup__header}>Support</p>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconDocs}`}
                  />
                  <a
                    aria-label="Documentation"
                    href={"/docs"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.popup__title}>
                    Documentation
                  </a>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconStart}`}
                  />
                  <a
                    aria-label="Quick Start"
                    href={"/docs/quick-start"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.popup__title}>
                    Quick Start
                  </a>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconStatus}`}
                  />
                  <a
                    aria-label="System Status"
                    href="https://status.volterra.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.popup__title}>
                    System Status
                  </a>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconFeatureReqs}`}
                  />
                  <a
                    aria-label="Feature Requests"
                    href="https://ideas.volterra.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.popup__title}>
                    Feature Requests
                  </a>
                </div>
                <div className={styles.popup__item}>
                  <div
                    className={`${styles.popup__icon} ${styles.popup__iconCompliance}`}
                  />
                  <a
                    aria-label="Compliance"
                    href="/company/compliance"
                    className={styles.popup__title}>
                    Compliance
                  </a>
                </div>
              </div>
              <div
                className={`${styles.popup__section} ${styles.popup__sectionLast}`}>
                <Link
                  aria-label="Pricing"
                  to={"/pricing"}
                  className={styles.popup__title}>
                  Pricing
                </Link>
              </div>
              <div className={styles.popup__buttons}>
                <button
                  aria-label="Log in"
                  className={`${styles.popup__button} ${styles.popup__buttonOutline}`}
                  onClick={loginClickHandler}>
                  Log in
                </button>
                <button
                  aria-label="Sign up"
                  className={`${styles.popup__button}`}
                  onClick={signUpClickHandler}>
                  Sign up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Header
